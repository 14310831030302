html, body, div, span, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, abbr, address, cite, code, del, dfn, em, img, ins, kbd, q, samp, small, strong, sub, sup, var, b, i, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, article, aside, canvas, details, figcaption, figure, footer, header, hgroup, menu, nav, section, summary, time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    /* font-size: 100%; */
    vertical-align: baseline;
    background: transparent;
    font-family: -apple-system,Helvetica Neue,Helvetica,PingFang SC,Hiragino Sans GB,Microsoft YaHei,SimSun,Segoe UI,Roboto,sans-serif;
  }
  .scrollable {
    width: 100%;
    height: 100%;
    margin: 0 auto;
    overflow-x: hidden;
    /*overflow-y: scroll;*/
    -webkit-overflow-scrolling: touch;
    overflow-scrolling: touch;
    z-index:-1;
  }
  
  /*html {
    width: 100%;
    height: 100%;
    overflow: hidden;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: antialiased;
  }*/
  
  body {
    color: #333;
    font-size: 0.28rem !important;
    font-family: "Microsoft YaHei", Helvetica, STHeiTi, Arial, sans-serif;
    background: #fff;
    width: 100%;
    height: 100%;
    overflow: auto;
    /* position: relative; */
  }
  /* html,body{
    min-height: 100%;
    padding-bottom: env(safe-area-inset-bottom);
  } */
  /* iphonex适配样式 */
  /* @supports(bottom:env(safe-area-inset-bottom)){
    div{
      margin-bottom: env(safe-area-inset-bottom);
    }
  } */

  body {
    padding-bottom: constant(safe-area-inset-bottom);
    padding-bottom: env(safe-area-inset-bottom);
  }

  /*iphoneX*/
@media only screen and (device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3) {
  .van-tabbar--fixed{
        padding-bottom: 0.26667rem !important;
        padding-bottom: constant(safe-area-inset-bottom);
        padding-bottom: env(safe-area-inset-bottom);
    }
}
/*iphoneX Max*/
@media only screen and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 3) {
  .van-tabbar--fixed{
        padding-bottom: 0.26667rem !important;
        padding-bottom: constant(safe-area-inset-bottom);
        padding-bottom: env(safe-area-inset-bottom);
    }
}
  
  html, body, form, fieldset, p, div, h1, h2, h3, h4, h5, h6 {
    -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
    -webkit-text-size-adjust: none;
  }
  
  a {
    background: transparent;
    text-decoration: none;
    outline: 0;
  }
  
  input, a:active,button, button:active, button:focus {
    outline: 0;
    border: none;
    background-color:transparent;
    font-family: "Microsoft YaHei", Helvetica, STHeiTi, Arial, sans-serif;
  }
  textarea {
    border: none;
    resize: none;
    -webkit-appearance: none;
    outline: 0;
    font-family: "Microsoft YaHei", Helvetica, STHeiTi, Arial, sans-serif;
  }
  
  fieldset, img {
    border: 0;
  }
  
  i, em {
    font-style: normal;
  }
  
  ol, ul, li {
    list-style: none;
  }
  
  dl, dt, dd, ul, li {
    margin: 0;
    padding: 0;
  }
  
  h1, h2, h3, h4, h5, h6 {
    font-weight: normal;
    font-size: 100%;
  }
  
  table {
    border-collapse: collapse;
    border-spacing: 0;
  }
  
  * {
    box-sizing: border-box;
  }
  input::-moz-placeholder{
    color:#999;
  }
  input::placeholder{
    color:#999;
  }
  img{
    display: block;
    height: auto !important;
  }
  .bdr-b{
    border-bottom: solid 0.02667rem #eee;
  }
 .mt-10{
  margin-top: 0.26667rem;
 } 
 .mt-20{
  margin-top: 0.53333rem;
 } 
 .mt-40{
  margin-top: 1.06667rem;
 }
 .mb-20{
  margin-bottom: 0.53333rem;
 }